import React from 'react';
import {
    Box,
    useColorModeValue,
} from '@chakra-ui/react';
import { BloqueInfo} from './BloqueInfo';

export function ExperienciaLaboral(){

    const bg = useColorModeValue('gray.200', 'gray.700')
    const color = useColorModeValue('gray.800', 'gray.200')

    return(
        <>
            <BloqueInfo 
                    // Incluyo la cabecera solo en el primero
                    colorBg={bg}
                    colorColor={color}
                    cabecera={'Experiencia laboral'}
                    titulo='Técnico informático en Sermicro'
                    fecha='Septiembre 2024 - Actualmente'
                    descripcion="Instalación y configuración de equipos en la provincia de Cádiz."
                    badge1='hardware'
                    badge2='software'
                    badge3='redes'
                    badge4='TPV'
                    colorBadge={'green'}
                />    
            <BloqueInfo 
                    // Incluyo la cabecera solo en el primero
                    colorBg={bg}
                    colorColor={color}
                    titulo='Técnico informático'
                    fecha='Septiembre 2024'
                    descripcion="Instalación y configuración de servidores Pyramid #7 en centros comerciales LIDL de la provincia de Cádiz."
                    badge1='Servidores'
                    badge2='Pyramid #7'
                    badge3='hardware'
                    colorBadge={'green'}
                />    
            <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='Técnico informático'
                    fecha='Junio 2024'
                    descripcion="Instalación y configuración de equipos informáticos en Jerez de la Frontera (Cádiz)."
                    badge1='Windows'
                    badge2='hardware'
                    badge3='cmd'
                    badge4='scripting'
                    colorBadge={'green'}
                />
            <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='Técnico informático'
                    fecha='Febrero 2024'
                    descripcion="Instalación de equipamiento y configuración de los sistemas INFOREG y DICIREG para los juzgados de la provincia de Badajoz."
                    badge1='software'
                    badge2='hardware'
                    badge3='instalación de equipos'
                    badge4='configuración de redes'
                    badge5='sistemas operativos'
                    colorBadge={'green'}
                />
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='Técnico informático'
                    fecha='Diciembre 2023'
                    descripcion="Resolución de incidencias hardware y software de los equipos informáticos del Museo Nacional de Arte Romano de Mérida. Creación de usuarios con Active Directory."
                    badge1='cmd'
                    badge2='Active Directory'
                    badge3='instalación de equipos'
                    badge4='hardware'
                    badge5='sistemas operativos'
                    colorBadge={'green'}
                />
                <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='Técnico informático'
                    fecha='Octubre 2023'
                    descripcion="Instalación y configuración de equipos en los Juzgados de Badajoz."
                    badge1='cmd'
                    badge2='drivers'
                    badge3='instalación de equipos'
                    badge4='networks'
                    badge5='sistemas operativos'
                    colorBadge={'green'}
                />
            <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='Creación de tutoriales Python y Bash para Oyola.dev'
                    fecha='Diciembre 2022 - Actualmente'
                    descripcion='Escribiendo cursos de Python (Básico, POO, MongoDB y Flask) y scripting (Bash)'
                    badge1='Python'
                    badge2='POO'
                    badge3='MongoDB'
                    badge4='Bash'
                    badge5='scripting'
                    colorBadge={'green'}
                />
            <BloqueInfo 
                    colorBg={bg}
                    colorColor={color}
                    titulo='BlitzTCG'
                    fecha='Agosto 2022'
                    descripcion='Desarrollador Blockchain'
                    badge1='Angular'
                    badge2='Blockchain'
                    badge3='c#'
                    badge4='Firebase'
                    badge5='Unity'
                    colorBadge={'green'}
                />
            
            <BloqueInfo
                    colorBg={bg}
                    colorColor={color}
                    titulo='Desarrollador Full Stack'
                    fecha='Octubre 2012 - Febrero 2022'
                    descripcion='Desarrollo y explotación de sitios web desde cero.'
                    badge1='Javascript'
                    badge2='Reactjs y Svelte'
                    badge3='SEO'
                    badge4='Python'
                    badge5='Wordpress'
                    colorBadge={'green'}
                />
            
            <BloqueInfo
                    colorBg={bg}
                    colorColor={color}
                    titulo='Audinex Ingeniería'
                    fecha='Junio 2009'
                    descripcion='Desarrollo de sitios web (Frontend).'
                    badge1='CSS'
                    badge2='HTML'
                    badge3='PHP'
                    badge4='SQL'
                    badge5='Symfony'
                    colorBadge={'green'}
                />
            
            <BloqueInfo
                    colorBg={bg}
                    colorColor={color}
                    titulo='Extérnal Consultoría'
                    fecha='Junio 2008'
                    descripcion='Desarrollo de sitios web (Backend - Frontend).'
                    badge1='ASP'
                    badge2='CSS'
                    badge3='Flash'
                    badge4='HTML'
                    badge5='SQL Server'
                    colorBadge={'green'}
                />
                
            <Box p={10}></Box>
        </>
    )
}
