import React from 'react';
import { 
    Box,
    List,
    ListItem,
    ListIcon,
    Text, 
    HStack, } from '@chakra-ui/react'
import { MdLaptop } from "react-icons/md";
import { RiFireLine } from "react-icons/ri";
import { FaPython, FaHtml5, FaJsSquare, FaCss3Alt, FaDocker, FaGitAlt } from "react-icons/fa";
import { AiOutlineCheckCircle, AiFillCheckCircle } from "react-icons/ai";

import {MuestroNft} from './MuestroNft'

export function ConsultaSecciones(){

    return(
        <>
            

            <Box p={4} display={{ md: 'flex' }} >
                <Box mt={{ base: 4, md: 0 }} ml={{ md: 6 }}>
                    <Box textAlign={'start'}>
                        <List spacing={3}>
                            
                            <ListItem m={5}>
                                <MuestroNft />
                                <Text fontSize={'small'} mt={2}>
                                    {/* This NFT was created for me. 
                                    <br></br>Click on the image for more details. */}
                                </Text>
                            </ListItem>



                            <List>
                                <ListItem>
                                    <ListIcon as={AiFillCheckCircle} color='green.300' />Programación
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={RiFireLine} color='red.500'/><Text>Escribir código es mi yoga particular</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={FaPython} color='yellow.500'/><Text>Python (Scripting, POO, Flask, FastAPI)</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={FaJsSquare} color='blue.500'/><Text>Javascript (React, Svelte)</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={MdLaptop} color='black.500'/><Text>Scripting en terminal con Linux, Mac y Windows </Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={FaGitAlt} color='black.500'/><Text>Git </Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={FaDocker} color='blue.300'/><Text>Docker</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={FaHtml5} color='orange.500'/><Text>HTML5</Text>
                                    </HStack>
                                </ListItem>
                                <ListItem>
                                    <HStack fontSize={'md'} ml={3} >
                                        <ListIcon as={FaCss3Alt} color='blue.500'/><Text>CSS</Text>
                                    </HStack>
                                </ListItem>
                            </List>


                            <ListItem>
                                    <ListIcon as={AiFillCheckCircle} color='green.300' />
                                    Redes
                                <List> 
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>
                                            Técnico en Sistemas Microinformáticos y Redes - SMR
                                            </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='gray.300'/><Text>
                                            Técnico Superior en Administración de Sistemas - ASIR
                                            </Text>
                                        </HStack>
                                    </ListItem>
                                </List>
                            </ListItem>

                            <ListItem>    
                                <ListIcon as={AiFillCheckCircle} color='green.300' />Linux
                                <List>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Me siento muy cómodo trabajando con sistemas Linux, Mac y Windows, en entornos gráficos y de terminal.</Text>
                                        </HStack>
                                    </ListItem>
                                </List>
                            </ListItem>  

                            <ListItem>    
                                <ListIcon as={AiFillCheckCircle} color='green.300' />Electrónica
                                <List>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Me han patrocinado varias marcas para el desarrollo de componentes experimentales: Bare Conductive, micro:bit, Particle y Nordic Semiconductor.</Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                        <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='orange.300' mt={1} />
                                            <Text>El programa El Hormiguero utilizó uno de mis botones para el juego Tenis Mental que fue pulsado por Will Smith :)</Text>
                                        </HStack>
                                    </ListItem>
                                </List>
                            </ListItem>   

                            <ListItem>
                                <ListIcon as={AiFillCheckCircle} color='green.300' />Seguridad Informática
                                <List>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Curso impartido por la uc3m a través de la plataforma eDX. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Curso de técnico en ciberseguridad impartido por INCIBE. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Seguridad en dispositivos móviles iOS por INCIBE. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Curso Básico de Ciberseguridad impartido por CCN. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Curso de Seguridad en infraestructuras de red impartido por CCN. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Curso Introduction to Cybersecurity impartido por Cisco. </Text>
                                        </HStack>
                                    </ListItem>
                                    {/*<ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Hacking de redes</Text>
                                        </HStack>
                                    </ListItem>
                                     <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='green.300'/><Text>Desarrollo de hardware de seguridad</Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='gray.300'/><Text>Desarrollo de herramientas de seguridad</Text>
                                        </HStack>
                                    </ListItem> */}
                                    {/* <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='gray.300'/><Text>Hacking Web applications</Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='gray.300'/><Text>Hacking mobile devices</Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='gray.300'/><Text>Hacking cloud</Text>
                                        </HStack>
                                    </ListItem> */}
                                    {/* <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='gray.300'/><Text>Forense</Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                        <HStack fontSize={'md'} ml={3} >
                                            <ListIcon as={AiOutlineCheckCircle} color='gray.300'/><Text>Ingeniería inversa</Text>
                                        </HStack>
                                    </ListItem> */}
                                </List>
                            </ListItem>

                            {/* <ListItem>    
                                <ListIcon as={AiOutlineCheckCircle} color='gray.300' />
                                Fluent English 
                            </ListItem> */}

                            <ListItem>
                                <ListIcon as={AiFillCheckCircle} color='green.300' />Otros datos de interés
                                <List>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Me gusta el ajedrez y la criptografía. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Colecciono nombres de dominio desde el año 2000. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Permisos de conducir: A, B, C, D y CAP de mercancías. </Text>
                                        </HStack>
                                    </ListItem>
                                    <ListItem>
                                            <HStack fontSize={'md'} ml={3} alignItems='flex-start'>
                                            <ListIcon mt={1} as={AiOutlineCheckCircle} color='green.300'/><Text>Según Trip Advisor he visitado el 23% del planeta. </Text>
                                        </HStack>
                                    </ListItem>
                                </List>
                            </ListItem>

                            <Box minH={'100px'}></Box>
                        </List>
                    </Box>
                </Box>
            </Box>
        </>
    )
}