import React from 'react';
import { useLocation } from 'wouter'

import { SiInstagram, SiGithub } from "react-icons/si";

import { 
    Button,
    HStack, 

} from '@chakra-ui/react'

export function Header(){

        const [location, setLocation] = useLocation();
        
        const irAbout = () => {
            setLocation("/");
        };
        const irInstagram = () => {
            setLocation("/instagram");
        };
        const irConstruyendo = () => {
            setLocation("/construyendo");
        };
        const irSensores = () => {
            setLocation("/sensores");
        };
        // const irSkills = () => {
        //     setLocation("/competencias");
        // };
        // const irProjects = () => {
        //     setLocation("/proyectos");
        // };
        const irCv = () => {
            setLocation("/cv");
        };
        // const irDapp = () => {
        //     setLocation("/dapp");
        // };

    return(
        <> 
            
            <HStack minW='90%' maxW='90%' justifyContent={'end'} mt={5} ml={5}>

                <Button borderRadius={'full'}
                    ml={2}
                    onClick={() => irAbout()}
                    variant='ghost'
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    >
                    Sobre mi
                </Button>
        
                <Button borderRadius={'full'}
                    onClick={() => irCv()}
                    variant='ghost'
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    >
                    CV
                </Button>

                <Button borderRadius={'full'}
                    onClick={() => irConstruyendo()}
                    variant='ghost'
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    >
                    Construyendo  
                </Button>

                {/* <Button borderRadius={'full'}
                    onClick={() => irSensores()}
                    variant='ghost'
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}>
                    sensores
                </Button> */}
                
                <Button borderRadius={'full'}
                    onClick={() => irInstagram()}
                    color={'white'}
                    variant='ghost'
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                    >
                    <SiInstagram />
                </Button>

                <Button
                    borderRadius={'full'}
                    onClick={() => window.location.href = "https://github.com/ladopixel"}  // O el enlace que prefieras
                    color={'cyan'}
                    variant='ghost'
                    _hover={{ bg: "transparent" }}
                    _active={{ bg: "transparent" }}
                >
                    <SiGithub />
                </Button>


                {/* <Button borderRadius={'full'}
                    ml={2}
                    onClick={() => irProjects()}
                    variant='solid'>
                    proyectos
                </Button> */}
                {/* <Button borderRadius={'full'}
                    ml={2}
                    onClick={() => irSkills()}
                    variant='solid'>
                    competencias
                </Button>  */}

                {/* <ColorModeSwitcher justifySelf="flex-end" /> */}

                {/* <Conector />  */}
                
            </HStack>
            
        </>
    )
}
